<template>
  <div class="grid grid-cols-8 gap-1 items-center">
    <font-awesome-icon
      icon="phone"
      class="text-dark-purple"
    ></font-awesome-icon>
    <select
      id="dropdownlist-address-type"
      ref="addPhoneType"
      class="inline-block border-steel-gray rounded h-8 px-1 py-0 text-sm"
      v-model="this.phone.phone_type"
    >
      <option
        v-for="phoneType in lookup_phone_types"
        :value="phoneType"
        :key="phoneType"
      >
        {{ phoneType }}
      </option>
    </select>
    <input
      type="text"
      v-model="this.phone.phone_number"
      v-on:keyup="formatPhoneNumber()"
      class="inline-block col-span-2 border-steel-gray rounded h-8 text-sm"
    />
    <label for="checkbox-is-primary-phone" class="pr-2">
      Primary?
      <input
        id="checkbox-is-primary-phone"
        type="checkbox"
        class="mx-4 border-steel-gray rounded "
        v-model="this.phone.is_primary"
    /></label>
    <label for="checkbox-is-public-phone" class="pl-4 pr-2"
      >Visible?
      <input
        id="checkbox-is-public-phone"
        type="checkbox"
        class="mx-4 border-steel-gray rounded"
        v-model="this.phone.is_public"
    /></label>
    <Button
      :text="editPhone === undefined ? 'Add Phone' : 'Update Phone'"
      @click="this.addPhoneNumber()"
    />
    <font-awesome-icon
      icon="times"
      class="ml-4 hover:cursor-pointer"
      aria-label="cancel phone changes"
      @click="cancel"
    />
  </div>
  <label class="col-span-full" v-if="errorMessages">{{ errorMessages }}</label>
</template>

<script>
import { mapGetters } from "vuex";
import Button from "@/components/reusable-components/base-level-components/Button";

export default {
  name: "addEditPhone",
  components: { Button },
  created() {
    this.phone = { ...this.editPhone };
  },
  data() {
    return {
      errorMessages: "",
      phone: {
        type: Object,
        default: {
          is_primary: false,
          is_public: false,
          phone_number: "",
          phone_type: "",
        },
      },
    };
  },
  props: {
    editPhone: {
      type: Object,
    },
  },
  methods: {
    addPhoneNumber() {
      if (this.validateNumber()) {
        this.$emit("phoneNumberChanged", this.phone);
      }
    },
    validateNumber() {
      if (
        this.phone.phone_number === undefined ||
        this.phone.phone_number?.length < 12
      ) {
        this.errorMessages = "Please enter a valid phone number";
        return false;
      } else if (
        this.phone.phone_type === undefined ||
        this.phone.phone_type === ""
      ) {
        this.errorMessages = "Please select a phone type";
        return false;
      }

      this.errorMessages = "";
      return true;
    },
    cancel() {
      this.errorMessages = "";
      this.$emit("cancelChange");
    },
    formatPhoneNumber() {
      // if input value is falsy eg if the user deletes the input, then just return
      if (!this.phone.phone_number) return this.phone.phone_number;

      // clean the input for any non-digit values.
      const phoneNumber = this.phone.phone_number.replace(/[^\d]/g, "");
      this.phone.phone_number = phoneNumber;
      // phoneNumberLength is used to know when to apply our formatting for the phone number
      const phoneNumberLength = phoneNumber.length;

      // we need to return the value with no formatting if its less than four digits
      // this is to avoid weird behavior that occurs if you  format the area code too early
      if (phoneNumberLength < 4) return phoneNumber;

      // if phoneNumberLength is greater than 4 and less the 7 we start to return
      // the formatted number
      if (phoneNumberLength < 7) {
        this.phone.phone_number = `(${phoneNumber.slice(
          0,
          3
        )}) ${phoneNumber.slice(3)}`;
      } else if (phoneNumberLength <= 10) {
        this.phone.phone_number = `(${phoneNumber.slice(
          0,
          3
        )}) ${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6, 10)}`;
      } else {
        // finally, if the phoneNumberLength is greater then seven, we add the last
        // bit of formatting and return it.
        this.phone.phone_number = `(${phoneNumber.slice(
          0,
          3
        )}) ${phoneNumber.slice(3, 6)}-${phoneNumber.slice(
          6,
          10
        )} x${phoneNumber.slice(10)}`;
      }
    },
  },
  computed: {
    ...mapGetters(["lookup_phone_types"]),
  },
};
</script>

<style scoped>
select {
  @apply border-steel-gray rounded py-0;
}
</style>
