<template>
  <div class="grid grid-cols-8 gap-1 items-center">
    <font-awesome-icon icon="envelope" class="text-dark-purple" />
    <select
      id="dropdownlist-address-type"
      ref="addPhoneType"
      class="inline-block border-steel-gray rounded h-8 py-0"
      v-model="this.email.email_type"
    >
      <option
        v-for="emailType in lookup_email_types"
        :key="emailType"
        class="h-8"
        :value="emailType"
      >
        {{ emailType }}
      </option>
    </select>
    <input
      type="text"
      v-model.trim="this.email.email_address"
      class="inline-block col-span-2 border-steel-gray rounded h-8"
    />
    <div>
      <label for="checkbox-is-primary-phone">Primary? </label>
      <input
        id="checkbox-is-primary-phone"
        type="checkbox"
        class="mx-4 border-steel-gray rounded"
        v-model="this.email.is_primary"
      />
    </div>
    <div>
      <label for="checkbox-is-public-phone">Visible? </label>
      <input
        id="checkbox-is-public-phone"
        type="checkbox"
        class="mx-4 border-steel-gray rounded"
        v-model="this.email.is_public"
      />
    </div>
    <Button :text="editEmails === undefined ? 'Add Email': 'Update Email'" @click="this.addEmail()" />

    <font-awesome-icon
      icon="times"
      class="ml-4 hover:cursor-pointer"
      aria-label="cancel phone changes"
      @click="cancel"
    />

    <label class="col-span-full" v-if="errorMessages">{{
      errorMessages
    }}</label>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Button from "@/components/reusable-components/base-level-components/Button";

export default {
  name: "addEditEmail",
  components: {Button},
  created() {
    this.email = { ...this.editEmails };
  },
  data() {
    return {
      errorMessages: "",
      email: {
        type: Object,
        default: {
          is_primary: false,
          is_public: false,
          email_address: "",
          email_type: "",
        },
      },
    };
  },
  props: {
    editEmails: {
      type: Object,
    },
  },
  methods: {
    addEmail() {
      if (this.validate()) {
        this.$emit("emailChanged", this.email);
      }
    },
    validate() {
      if (
        this.email.email_address === undefined ||
        this.email.email_address?.length < 8
      ) {
        // console.log("error?");
        this.errorMessages = "Please enter a valid email address";
        return false;
      } else if (
        this.email.email_type === undefined ||
        this.email.email_type === ""
      ) {
        this.errorMessages = "Please select an email type";
        return false;
      }

      this.errorMessages = "";
      return true;
    },
    cancel() {
      this.errorMessages = "";
      this.$emit("cancelChange");
    },
  },
  computed: {
    ...mapGetters(["lookup_email_types"]),
  },
};
</script>
