<template>
  <div class="col-span-full">
    <div class="grid grid-cols-8 gap-1 profile_box items-center">
      <div class="thirdhead-text col-span-1">
        <label for="dropdownlist-address-type">Address Type </label>
      </div>
      <select
        id="dropdownlist-address-type"
        v-model="this.address.address_type"
        class="col-span-3"
      >
        <option
          v-for="addressType in lookup_address_types"
          :key="addressType"
          :value="addressType"
        >
          {{ addressType }}
        </option>
      </select>

      <div class="col-span-1 col-start-1 thirdhead-text">
        <label for="textbox-address"> Address 1 </label>
      </div>
      <div class="col-span-3">
        <input
          id="textbox-address"
          type="text"
          class="w-full border-steel-gray rounded h-8"
          v-model.trim="this.address.address_line_one"
          maxlength="200"
        />
      </div>

      <div class="col-span-1 col-start-1 thirdhead-text">
        <label for="textbox-employer-address2"> Address 2 </label>
      </div>
      <div class="col-span-3">
        <input
          id="textbox-employer-address2"
          type="text"
          class="w-full border-steel-gray rounded h-8"
          v-model.trim="this.address.address_line_two"
          maxlength="200"
        />
      </div>
      <div class="col-span-1  col-start-1 thirdhead-text pr-4">
        <label for="textbox-employer-city">City</label>
      </div>
      <input
        id="textbox-employer-city"
        class="col-span-1 border-steel-gray rounded h-8"
        type="text"
        v-model.trim="this.address.city"
        maxlength="200"
      />

      <div class="col-span-1 thirdhead-text pr-4 text-center">
        <label for="dropdownlist-states">State</label>
      </div>

      <select
        id="dropdownlist-states"
        class="col-span-1"
        v-model="this.address.state"
      >
        <option
          v-for="(state, state_id) in lookup_states"
          :value="state_id"
          :key="state_id"
        >
          {{ state }}
        </option>
      </select>
      <div class="col-span-1 thirdhead-text pr-4 text-center">
        <label for="textbox-employer-zip">Zip Code</label>
      </div>
      <input
        id="textbox-employer-zip"
        class="col-span-1 border-steel-gray rounded h-8"
        type="text"
        v-model.trim="this.address.zip_code"
        maxlength="20"
        v-on:keyup="cleanZip()"
      />
      <div class="thirdhead-text col-span-2 col-start-1">
        <label for="checkbox-is-primary-address">Primary Address? </label>
        <input
          id="checkbox-is-primary-address"
          type="checkbox"
          class="mx-4 border-steel-gray rounded"
          v-model="this.address.is_primary"
        />
      </div>
      <div class="thirdhead-text col-span-2">
        <label for="checkbox-is-public-address">Visible Address? </label>
        <input
          id="checkbox-is-public-address"
          type="checkbox"
          class="mx-4 border-steel-gray rounded"
          v-model="this.address.is_public"
        />
      </div>
      <div class="col-span-full">
        <Button :text="editAddress === undefined ? 'Add Address': 'Update Address'" @click="this.addAddress()" />
        <font-awesome-icon
          icon="times"
          class="ml-4 hover:cursor-pointer"
          aria-label="cancel phone changes"
          @click="cancel"
        />
      </div>
    </div>
  </div>
  <label class="col-span-full" v-if="errorMessage"> {{ errorMessage }} </label>
  <div class="col-span-full profile_box"></div>
</template>

<script>
import { mapGetters } from "vuex";
import Button from "@/components/reusable-components/base-level-components/Button";

export default {
  name: "addEditEmail",
  components: {Button},
  created() {
    this.address = { ...this.editAddress };
  },
  data() {
    return {
      errorMessage: "",
      address: {
        type: Object,
        default: {
          is_primary: false,
          is_public: false,
          address_line_one: "",
          address_line_two: "",
          address_type: "",
          city: "",
          state: "",
          zip_code: "",
        },
      },
    };
  },
  props: {
    editAddress: {
      type: Object,
    },
  },
  methods: {
    ...mapGetters(["lookup_states"]),
    addAddress() {
      if (this.validate()) {
        this.$emit("addressChanged", this.address);
      }
    },
    validate() {
      if (this.address.state === undefined || this.address.state === "") {
        this.errorMessage = "Please select a state";
        return false;
      } else if (
        this.address.zip_code === undefined ||
        this.address.zip_code === ""
      ) {
        this.errorMessage = "Please enter a valid zip code";
        return false;
      } else if (this.address.city === undefined || this.address.city === "") {
        this.errorMessage = "Please enter a valid city";
        return false;
      } else if (
        this.address.address_line_one === undefined ||
        this.address.address_line_one === ""
      ) {
        this.errorMessage = "Please enter at least one valid address";
        return false;
      } else if (
        this.address.address_type === undefined ||
        this.address.address_type === ""
      ) {
        this.errorMessage = "Please select a valid address type";
        return false;
      }

      this.errorMessage = "";
      return true;
    },
    cancel() {
      this.errorMessage = "";
      this.$emit("cancelChange");
    },
    cleanzip() {},
  },
  computed: {
    ...mapGetters(["lookup_address_types", "lookup_states"]),
  },
};
</script>

<style scoped>
select {
  @apply border-steel-gray rounded h-8 py-0;
}
</style>
